const SAVING_ACCOUNT = '1'

export const onlyNumbers = value => value.replace(/[^\d]/g, '')

export const normalizeDate = value => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`
  }
  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(4, 8)}`
}

export const normalizeCPF = value => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3)}`
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}`
  }
  return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}-${onlyNums.slice(
    9,
    11
  )}`
}

export const normalizeDocument = document => {
  if (!document) {
    return document
  }
  const onlyNums = document.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3)}`
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}`
  }

  if (onlyNums.length < 12) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(
      6,
      9
    )}-${onlyNums.slice(9, 11)}`
  }

  if (onlyNums.length < 13) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(
      5,
      8
    )}/${onlyNums.slice(8, 12)}`
  }

  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(
    8,
    12
  )}-${onlyNums.slice(12, 14)}`
}

export const normalizeCNPJ = cnpj => {
  if (!cnpj) return ''

  cnpj = cnpj.replace(/[^\d]/g, '')

  if (cnpj.length < 3) return cnpj

  if (cnpj.length === 3) return `${cnpj.slice(0, 2)}.${cnpj.slice(2)}`

  if (cnpj.length < 6) return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}`

  if (cnpj.length < 9) return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}`

  if (cnpj.length < 13)
    return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}`

  return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(
    8,
    12
  )}-${cnpj.slice(12, 14)}`
}

export const normalizeCRP = value => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length < 3) {
    return onlyNums
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`
  }
  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 20)}`
}

export const normalizeCEP = value => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums.length <= 5) {
    return onlyNums
  }
  if (onlyNums.length === 6) {
    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 6)}`
  }
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`
}

// (99) 9 9999-9999
export const normalizePhone = value => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length < 3) {
    return '(' + onlyNums.slice(0, 2)
  }
  if (onlyNums.length < 4) {
    return '(' + onlyNums.slice(0, 2) + ') ' + onlyNums.slice(2, 3)
  }
  if (onlyNums.length === 7) {
    return '(' + onlyNums.slice(0, 2) + ') ' + onlyNums.slice(2) + '-'
  }
  if (onlyNums.length <= 2) {
    return onlyNums
  }
  if (onlyNums.length <= 8) {
    return '(' + onlyNums.slice(0, 2) + ') ' + onlyNums.slice(2)
  }

  if (onlyNums.length === 10) {
    return (
      '(' +
      onlyNums.slice(0, 2) +
      ') ' +
      onlyNums.slice(2, 3) +
      ' ' +
      onlyNums.slice(3, 6) +
      '-' +
      onlyNums.slice(6, 10)
    )
  }
  return (
    '(' +
    onlyNums.slice(0, 2) +
    ') ' +
    onlyNums.slice(2, 3) +
    ' ' +
    onlyNums.slice(3, 7) +
    '-' +
    onlyNums.slice(7, 11)
  )
}

// 9?9?9?9?9?9?9?9?9?9?9?9?9?9?
export const normalizeInternationalPhone = value => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')

  return onlyNums.slice(0, 14)
}

// (99) 99999-9999
// TODO: removes this code, because it is basically the same as the normalizePhone function
export const normalizeCellPhone = value => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length < 3) {
    return '(' + onlyNums.slice(0, 2)
  }
  if (onlyNums.length < 4) {
    return '(' + onlyNums.slice(0, 2) + ') ' + onlyNums.slice(2, 3)
  }

  if (onlyNums.length <= 8) {
    return '(' + onlyNums.slice(0, 2) + ') ' + onlyNums.slice(2)
  }

  if (onlyNums.length === 10) {
    return (
      '(' +
      onlyNums.slice(0, 2) +
      ') ' +
      onlyNums.slice(2, 3) +
      ' ' +
      onlyNums.slice(3, 6) +
      '-' +
      onlyNums.slice(6, 10)
    )
  }
  return (
    '(' +
    onlyNums.slice(0, 2) +
    ') ' +
    onlyNums.slice(2, 3) +
    ' ' +
    onlyNums.slice(3, 7) +
    '-' +
    onlyNums.slice(7, 11)
  )
}

// 9999 9999 9999 9999
export const numberCreditCard = value => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums.length <= 16) {
    return onlyNums.match(/.{1,4}/g).join(' ')
  }
}

//  mm/aa
export const overdueCreditCard = value => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')

  if (onlyNums.length < 3) {
    return onlyNums
  }

  if (onlyNums.length === 3) {
    return onlyNums.slice(0, 2) + '/' + onlyNums.slice(2, 3)
  }

  if (onlyNums.length < 5) {
    return onlyNums.slice(0, 2) + '/' + onlyNums.slice(2, 4)
  }
}

// 99999-D
export const bankAccount = value => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 5) {
    return onlyNums
  }

  if (onlyNums.length < 12) {
    return onlyNums.slice(0, onlyNums.length - 1) + '-' + onlyNums.slice(-1)
  }
}

// 9999-D
export const bankAgency = (value, bank) => {
  if (!value) {
    return value
  }

  const onlyNums = value.replace(/[^\d|X|x]/g, '')
  if (onlyNums.length <= 4) {
    return onlyNums
  }

  if (bank !== 'Banco do Brasil' && bank !== 'Bradesco') {
    return onlyNums.slice(0, 4)
  }

  if (onlyNums.length < 6) {
    return onlyNums.slice(0, onlyNums.length - 1) + '-' + onlyNums.slice(-1)
  }
}

// 0,00
// TODO: improve this helper name, it's not intuitive at all
export const price = value => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 2) {
    return onlyNums
  }

  if (onlyNums.length > 2) {
    return onlyNums.slice(0, onlyNums.length - 2) + ',' + onlyNums.slice(-2)
  }
}

export const normalizeCurrency = value => {
  const onlyNumbers = value.replace(/\D/g, '')

  if (!onlyNumbers) {
    return '0,00'
  }

  const parsedValue = parseFloat(onlyNumbers) / 100

  return parsedValue.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
}

// TODO: remove the Banco Original repeated occurence
export const getLimit = (bank, account_type) => {
  switch (bank) {
    case 'Mercado Pago':
      return 14
    case 'Caixa Econômica':
      if (account_type === SAVING_ACCOUNT) return 14
      return 12
    case 'Via Credi':
      return 12
    case 'Sicoob':
    case 'Banrisul':
    case 'BRB':
    case 'Inter':
    case 'Modal':
      return 10
    case 'Nubank':
    case 'Neon':
    case 'Votorantim':
      return 11
    case 'Banco do Brasil':
    case 'Mercantil do Brasil':
    case 'Santander':
    case 'Safra':
    case 'PagSeguro':
    case 'Unicred':
      return 9
    case 'Bradesco':
    case 'Banco Original':
    case 'C6':
    case 'Stone':
    case 'Cora':
    case 'BS2':
      return 8
    case 'Itaú':
      return 6
    case 'Sicredi':
    case 'BTG Pactual':
      return 7
    default:
      return 9
  }
}

export const getAccountPlaceholder = (bank, account_type) => {
  switch (bank) {
    case 'Caixa Econômica':
      if (account_type === SAVING_ACCOUNT) return 'XXXX999999999-D'
      return 'XXX99999999-D'
    case 'Uniprime':
    case 'Banco Genial':
      return '9999-D'
    case 'Itaú':
    case 'Banco Topazio':
    case 'Ótimo':
    case 'Cresol':
      return '99999-D'
    case 'Banco Daycoval':
    case 'Banco do Nordeste':
    case 'Uniprime Norte do Paraná':
    case 'Banco da Amazonia':
    case 'BRL Trust DTVM':
    case 'Banco Omni':
    case 'Polocred':
    case 'BTG Pactual':
      return '999999-D'
    case 'Banco Original':
    case 'BS2':
    case 'Stone':
    case 'Bradesco':
    case 'Cooperativa Central de Credito Noroeste Brasileiro':
    case 'Cora':
    case 'Next':
      return '9999999-D'
    case 'Sicoob':
    case 'Itaú':
    case 'Banpará':
    case 'Banrisul':
    case 'Inter':
    case 'BRB':
    case 'Modal':
      return '999999999-D'
    case 'PJBank':
    case 'Nubank':
    case 'Juno':
    case 'Neon':
    case 'Votorantim':
      return '9999999999-D'
    case 'Via Credi':
    case 'JP Morgan':
      return '99999999999-D'
    case 'Mercado Pago':
      return '9999999999999-D'
    case 'BNP Paribas Brasil':
      return '999999-DDD'
    case 'CCR de São Miguel do Oeste':
      return '99999'
    case 'Sicredi':
      return '999999D'
    case 'Citibank':
    case 'Acesso Soluções de pagamento':
      return '99999999'
    case 'Rendimento':
    case 'Agibank':
      return '9999999999'
    case 'Global SCM':
      return '99999999999'
    default:
      return '99999999-D'
  }
}

export const getAgencyPlaceholder = bank => {
  switch (bank) {
    case 'Money Plus':
      return '9'
    case 'Banco do Nordeste':
    case 'BNP Paribas Brasil':
    case 'BRL Trust DTVM':
    case 'Banco Banese':
      return '999'
    case 'Banco do Brasil':
    case 'Bradesco':
    case 'Rendimento':
    case 'Next':
    case 'Cresol':
      return '9999-D'
    default:
      return '9999'
  }
}

export const normalizeBankAccount = (value, bank, account_type) => {
  const generateBankAccountRegex = () => {
    switch (bank) {
      case 'Banco do Brasil':
        return /[^\dXx]/g
      default:
        return /[^\d]/g
    }
  }

  const limit = getLimit(bank, account_type)
  if (!value) {
    return value
  }
  const bankAccountRegex = new RegExp(generateBankAccountRegex())

  const validCharacters = value.replace(bankAccountRegex, '').replace(/x/g, 'X')

  if (bank === 'Sicredi') {
    return value.slice(0, limit)
  }

  if (validCharacters.length === limit) {
    return validCharacters.slice(0, limit - 1) + '-' + validCharacters.slice(limit - 1, limit)
  }

  if (validCharacters.length >= limit && validCharacters.length > 1) {
    return validCharacters.slice(0, limit - 1) + '-' + validCharacters.slice(limit - 1, limit)
  }

  if (validCharacters.slice(0, validCharacters.length - 1).length >= 1) {
    return (
      validCharacters.slice(0, validCharacters.length - 1) +
      '-' +
      validCharacters.slice(validCharacters.length - 1, validCharacters.length)
    )
  } else {
    return validCharacters.slice(validCharacters.length - 1, validCharacters.length)
  }
}
